import React, { useEffect } from 'react';
import { isProd } from '../../lib/env';
import { useSelector } from 'react-redux';

const KoreAiScript: React.FC = () => {
  const { systemConfiguration } = useSelector((state: any) => state.app);
  const isKoreAiEnabled: boolean = systemConfiguration.featureFlags?.kore_ai || false;

  useEffect(() => {
    if (isKoreAiEnabled) {
      const script = document.createElement('script');
      const scriptUrl = isProd()
        ? 'https://hcm.cdn.elmotalent.com.au/ai-assist/koreai.js'
        : 'https://hcm.cdn.elmodev.com/ai-assist/koreai.js';

      script.type = 'module';
      script.innerHTML = `
        import { elmoAssist } from '${scriptUrl}';
        elmoAssist.setup({'menu_url': '/api/v1/navigation'});
      `;

      document.head.appendChild(script);
    }
  }, []);

  return null;
};

export default KoreAiScript;
