import React, { useEffect } from 'react';
import './layout/App.scss';
import { Loader, ThemeProvider, ApplicationTheme } from 'elmo-elements';
import { isAuthenticated } from './lib/auth/check';
import { Router } from 'react-router-dom';
import history from './lib/history';
import AppLayout, { AppLoading } from './layout';
import Catch from './layout/Catch';
import { NotificationProvider } from './state/App/notificationsContext';
import { useDispatch, useSelector } from 'react-redux';
import * as Pendo from './lib/pendo';
import { appInit } from './state/App';
import { SystemConfigurationContext } from './lib/systemConfiguration';
import { SystemConfiguration } from './lib/systemConfiguration/type';
import AuthAccess from './lib/auth/access';
import { Core } from '@eds/core';
import { Core as CoreComponent } from './element/Core';
import KoreAiScript from './element/KoreAiScript';

const App = (): React.ReactElement => {
  const { systemConfiguration, theme, isLoaded, error } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const { currentUser } = AuthAccess;

  useEffect(() => {
    isAuthenticated().then(() => {
      dispatch(appInit());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded) {
    return (
      <Core>
        <CoreComponent>
          <AppLoading isLoaded={isLoaded} error={error}>
            <ThemeProvider theme={theme}>
              <div className="container">
                <Router history={history}>
                  <Catch>
                    <NotificationProvider>
                      <SystemConfigurationContext.Provider value={systemConfiguration}>
                        <SystemConfigurationContext.Consumer>
                          {(context: SystemConfiguration): React.ReactElement => {
                            if (currentUser?.id) {
                              Pendo.init(context.companySubDomain);
                            }
                            return <AppLayout />;
                          }}
                        </SystemConfigurationContext.Consumer>
                      </SystemConfigurationContext.Provider>
                    </NotificationProvider>
                  </Catch>
                </Router>
                <ApplicationTheme />
                <KoreAiScript />
              </div>
            </ThemeProvider>
          </AppLoading>
        </CoreComponent>
      </Core>
    );
  }

  return (
    <div className="app-loading-element">
      <Loader type="spinner" />
      Loading...
    </div>
  );
};

export default App;
