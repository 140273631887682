import { Button } from '@eds/button';
import { Flex } from '@eds/flex';
import { LoadingSpinner } from '@eds/loading';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ASSIGN_APPRAISAL_TO_DIRECT_REPORTS,
  ASSIGN_APPRAISAL_TO_SELECTED_REPORTS,
  DATE_FORMAT_YYYY_MM_DD,
  POLLING_APPRAISAL_ASSIGNMENT_TYPE,
} from '../../../../lib/const';
import { dateFormat } from '../../../../lib/date';
import history from '../../../../lib/history';
import { messages, t } from '../../../../lib/translation';
import { getRoute } from '../../../../lib/util';
import { resetState, startPoll } from '../../../../state/App';
import { NotificationsContext } from '../../../../state/App/notificationsContext';
import { StartPollPayloadType } from '../../../../state/App/type';
import { AppraisalAssignmentListPath } from '../../routes';
import { assignAppraisal, getAppraisal } from '../../state';
import AppraisalDuration from '../AppraisalDuration';
import AssignmentConfirmationModal from '../AssignmentConfirmation';
import AssignmentProgressModal from '../AssignmentProgress';
import EmployeeListView from '../EmployeeList/index';
import AssignmentCompletedModal from '../AssignmentCompleted';
import SelectAssigneeType from '../SelectAssigneeType';
import { Notice } from '@eds/notice';

export const AssignmentContainer = ({ match }: Record<string, any>): React.ReactElement => {
  const { add, reset } = useContext(NotificationsContext);
  const dispatch = useDispatch();
  const { selectedAppraisal, isLoaded, error } = useSelector((state: any) => state.assignAppraisal);
  const pollingJobs = useSelector((state: any) => state.app.pollingJobs);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [completedJobId, setCompletedJobId] = useState<number>();
  const [assignmentInProgress, setAssignmentInProgress] = useState<boolean>(false);
  const [assignmentConfirmationModalOpen, setAssignmentConfirmationModalOpen] =
    useState<boolean>(false);
  const [assignmentProgressModalOpen, setAssignmentProgressModalOpen] = useState<boolean>(false);
  const [assignmentCompletedModalOpen, setAssignmentCompletedModalOpen] = useState<boolean>(false);
  const [assigneeType, setAssigneeType] = useState<string>('');

  /**
   * assignment handling:
   * 1. we ask user to select date and select employees, then this assign button can be enabled
   * 2. If there is warnings or errors from selected appraisal, we disable the form and assign button
   * 3. when user select date and employess, click assign button, we show the confirmation modal.
   * 4. when user cancel confirmation modal, nothing will be happening, data will remain selected.
   *    when user confirm assignment, we immediately show in progress modal, this including the assignment post
   *    and job polling.
   * 5. when polling job return `pollingJobs[jobid]?.data` which means successfully finished, we will close in progress modal
   *    show successful modal, also clean the selected employees, meanwhile set the completedjobid,
   *    so employee list can be refreshed.
   * 6. user can close the inprogress modal or refresh the window during assignment, we will detect if there is jobid
   *    from the selected appraisal, show the in progress modal again when they refresh the whole browser.
   *    also restart the polling, until we receive the job which is completed.
   * 7. the internal assignmentInProgress state can make sure the form is still disabled during assignment,
   *    we dont allow user to make a new assignment during the previous assignment job running.
   */
  useEffect(() => {
    /* istanbul ignore else */
    if (selectedAppraisal && pollingJobs && pollingJobs[selectedAppraisal.activeJobId]?.data) {
      setAssignmentInProgress(false);
      setAssignmentProgressModalOpen(false);
      setAssignmentCompletedModalOpen(true);
      // clear the selected user list
      setSelectedUserIds([]);
      setCompletedJobId(selectedAppraisal.activeJobId);
    }
  }, [pollingJobs, selectedAppraisal]);

  useEffect(() => {
    dispatch(getAppraisal(parseInt(match.params.id, 10)));
  }, [dispatch, match.params.id]);

  // if selected appraisal template is fixed duration, we use this start date as appraisal assignment start date
  useEffect(() => {
    /* istanbul ignore else */
    if (selectedAppraisal && selectedAppraisal.isFixedDuration) {
      setStartDate(new Date(selectedAppraisal.startDate));
    }
  }, [selectedAppraisal, setStartDate]);

  // if there is active job from this selected appraisal template, we mark the assignment is in progress
  // and start polling
  useEffect(() => {
    /* istanbul ignore else */
    if (
      selectedAppraisal &&
      selectedAppraisal.activeJobId &&
      (!completedJobId || selectedAppraisal.activeJobId !== completedJobId)
    ) {
      setAssignmentInProgress(true);
      setAssignmentProgressModalOpen(true);
      const job: StartPollPayloadType = {
        jobId: selectedAppraisal.activeJobId,
        id: selectedAppraisal.id,
        type: POLLING_APPRAISAL_ASSIGNMENT_TYPE,
        pushNotification: false,
      };
      dispatch(startPoll(job));
    }
  }, [dispatch, selectedAppraisal, completedJobId]);

  useEffect(
    () => (): void => {
      reset && reset();
      dispatch(resetState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (selectedAppraisal) {
      const { warnings } = selectedAppraisal;
      if (add && warnings && warnings.length > 0) {
        warnings.forEach((warning: string) => {
          add({
            message: warning,
            type: 'danger',
          });
        });
      }
    }
  }, [selectedAppraisal, add]);

  useEffect(() => {
    /* istanbul ignore else */
    if (error && isLoaded) {
      add &&
        add({
          message: error.message,
          type: 'danger',
        });
    }
  }, [error, isLoaded, add]);

  // we only enabled assign button when there is no errors from selected appraisal,
  // start date picked and employees are selected
  const assignButtonDisabled = useMemo<boolean>(
    () =>
      !startDate ||
      !selectedAppraisal ||
      !assigneeType ||
      (!selectedUserIds.length && assigneeType === ASSIGN_APPRAISAL_TO_SELECTED_REPORTS),
    [startDate, selectedUserIds, selectedAppraisal, assigneeType]
  );

  // we disabled the form, like the date picker when there is no errors from selected appraisal
  // and currently assignment is not in progress
  const formDisabled = useMemo<boolean>(() => {
    const hasWarnings =
      selectedAppraisal && selectedAppraisal.warnings && selectedAppraisal.warnings.length;
    return assignmentInProgress || hasWarnings;
  }, [assignmentInProgress, selectedAppraisal]);

  const onAssignConfirm = (): void => {
    reset && reset();
    setAssignmentConfirmationModalOpen(false);
    setAssignmentInProgress(true);
    setAssignmentProgressModalOpen(true);
    dispatch(
      assignAppraisal({
        appraisalId: selectedAppraisal.id,
        assignmentType: assigneeType,
        startDate: dateFormat(startDate, DATE_FORMAT_YYYY_MM_DD),
        userIds: selectedUserIds,
      })
    );
  };

  const onClickBack = (): void => {
    history.push(getRoute(AppraisalAssignmentListPath));
  };

  if (!isLoaded || !selectedAppraisal) {
    return (
      <Flex marginTop="xxlarge" marginBottom="xxlarge" justifyContent="center">
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" gap="large">
      <AppraisalDuration
        startDate={startDate}
        setStartDate={setStartDate}
        isFixedDuration={selectedAppraisal.isFixedDuration}
        {...(selectedAppraisal.isFixedDuration
          ? {
              appStartDate: new Date(selectedAppraisal.startDate),
              appEndDate: new Date(selectedAppraisal.endDate),
            }
          : {
              appStartPeriod: selectedAppraisal.startPeriod,
              appEndPeriod: selectedAppraisal.endPeriod,
              appPeriodScale: selectedAppraisal.periodScale,
              isDisabled: formDisabled,
            })}
      />
      <SelectAssigneeType
        selected={assigneeType}
        onSelect={setAssigneeType}
        isDisabled={formDisabled}
      />
      {assigneeType && assigneeType !== ASSIGN_APPRAISAL_TO_SELECTED_REPORTS && (
        <Notice
          tone="informative"
          description={
            `You are assigning the ${selectedAppraisal.title} ` +
            `to your ${
              assigneeType === ASSIGN_APPRAISAL_TO_DIRECT_REPORTS
                ? 'direct reports'
                : 'entire reporting line'
            }.`
          }
        />
      )}
      {assigneeType === ASSIGN_APPRAISAL_TO_SELECTED_REPORTS && (
        <EmployeeListView
          appraisalId={match.params.id}
          selectedUserIds={selectedUserIds}
          startDate={dateFormat(startDate, DATE_FORMAT_YYYY_MM_DD, true)}
          setSelectedUserIds={setSelectedUserIds}
          completedJobId={completedJobId}
        />
      )}
      <Flex gap="small">
        <Button
          tone="neutral"
          onClick={onClickBack}
          data-testid="assign-appraisal-back"
          label={t(messages.assignAppraisal.backButton)}
        />
        <Button
          disabled={assignButtonDisabled || formDisabled}
          onClick={(): void => setAssignmentConfirmationModalOpen(true)}
          data-testid="assign-appraisal"
          label={t(messages.assignAppraisal.assignButton)}
        />
      </Flex>
      <AssignmentConfirmationModal
        appraisalTitle={selectedAppraisal.title}
        assigneeType={assigneeType}
        selectedEmployeeNumber={selectedUserIds.length}
        onClose={(): void => {
          setAssignmentConfirmationModalOpen(false);
        }}
        onAssignConfirm={onAssignConfirm}
        isModalOpen={assignmentConfirmationModalOpen}
      />
      <AssignmentProgressModal
        onClose={(): void => {
          setAssignmentProgressModalOpen(false);
        }}
        isModalOpen={assignmentProgressModalOpen}
      />
      <AssignmentCompletedModal
        appraisalTitle={selectedAppraisal.title}
        onClose={(): void => {
          setAssignmentCompletedModalOpen(false);
        }}
        isModalOpen={assignmentCompletedModalOpen}
      />
    </Flex>
  );
};

export default withRouter(AssignmentContainer);
